import React from 'react';
import MainWrapper, {LogoImageContainer, MainContentSection} from "@deadline/common/ui/five.style";
import {Container} from "../components/Container";
import styled from "styled-components";
import {Link} from "gatsby";
import LogoImage from '@deadline/common/static/images/logoWhite.svg';
import {SEO} from "../components";
import Helmet from "react-helmet";

const TextWrapper = styled.div`
  margin-top: 100px;
  background: #fff;
  padding: 50px;
  border-radius: 5px;
  width: 100%;
  *{
    max-width: initial !important;
    text-align: justify !important;
     font-family: 'Roboto' !important;
    
  }
`;

function ErrorPage() {
    return (
        <MainWrapper>
            <Helmet>
                <title>
                    Simplicity Commerce | Privacy Policy
                </title>
            </Helmet>
            <Container className="mainContainer">
                <MainContentSection>
                    <LogoImageContainer>
                        <Link to={'/'}>
                            <img src={LogoImage} alt="logo" />
                        </Link>
                    </LogoImageContainer>

                    <TextWrapper>
                        <h1>Privacy Policy</h1>
                        <h3>Published Date: November 28th, 2019</h3>
                        <h2 id="purpose">Purpose</h2>
                        <p>Protecting your privacy is important to Simplicity Commerce, Inc. (“Simplicity Commerce”)</p>
                        <p>Simplicity Commerce provides services and develops software to help merchants sell better or more
                            efficiently online through various platforms, such as Shopify. Simplicity Commerce has adopted
                            this Privacy
                            Statement to guide you through the collection, use, retention, and disclosure of Personally
                            Identifiable Information (“Personal Data”, as further described below) that you may provide
                            while
                            using Simplicity Commerce websites, apps, and services in connection with these platforms
                            (collectively
                            referred to as “Services”). <a href="/domains-list">A listing of the Simplicity Commerce websites
                                and
                                domains is available here</a>. Simplicity Commerce encourages you to read this Privacy
                            Statement, as well
                            as our Terms and Conditions of Use, in order to understand how we collect and process
                            Personal Data in
                            the course of providing the Services and your interaction with the Services.</p>
                        <p>By using the Services, you consent to the collection, use, retention, and disclosure of your
                            Personal
                            Data for processing as described in, and subject to the limitations set out in, this Privacy
                            Statement.</p>
                        <p><strong>Note to Residents of the European Union:</strong> In order to comply with the
                            requirements of
                            the European General Data Protection Regulation (GDPR) for our European users, this Privacy
                            Statement
                            outlines the legal basis on which we process your Personal Data and provides other
                            information
                            required by the GDPR.</p>
                        <h2 id="personal-data">Personal Data</h2>
                        <p>Personal Data is any information that would identify a person directly, or indirectly in
                            combination
                            with data from other sources. For example, a full name, home or work address, phone number,
                            national
                            identification number (SSN, SIN, etc.), email address, banking details, IP address,
                            biometric data,
                            usage data, or any information that may individually identify a person.</p>
                        <p>Simplicity Commerce may collect Personal Data including without limitation your name, shipping and
                            billing
                            addresses, phone number, email address, payment information, IP address, and device
                            identifiers and/or
                            geolocation information, in the course of its Services, and may use or disclose that
                            Personal Data as
                            described with this Privacy Statement.</p>
                        <p>Simplicity Commerce may also create de-identified or anonymized data from Personal Data by
                            excluding data
                            components (such as your name, email address, etc.) that makes the data able to personally
                            identify
                            you, through obfuscation, or through other means. In addition, Simplicity Commerce may collect and
                            use
                            aggregated, anonymous information to provide data about the Services to advertisers,
                            potential
                            business partners and other unaffiliated entities. As this information does not identify a
                            person, and
                            is therefore not Personal Data, Simplicity Commerce’s use of such aggregated, anonymized and/or
                            de-identified data is not subject to this Privacy Statement.</p>
                        <h2 id="consent-and-collection-of-personal-data">Consent and Collection of Personal Data</h2>
                        <p>If you use a Simplicity Commerce website, or conduct a transaction through a Simplicity Commerce Service
                            where
                            Personal Data is essential, your consent is implied to collect and use your Personal Data to
                            facilitate that use or complete that transaction requested or initiated by you only.
                            Examples of
                            instances in which Personal Data may be collected by Simplicity Commerce are, but are not limited
                            to:</p>
                        <ul>
                            <li>When you install a Simplicity Commerce App,</li>
                            <li>If you make or return a purchase through a merchant who uses a Simplicity Commerce App,</li>
                            <li>When you access and navigate a Simplicity Commerce website, or engage in communication and/or
                                business
                                transactions with Simplicity Commerce Professional Services, Managed Services, Merchant
                                Success, or any
                                other Simplicity Commerce entity,
                            </li>
                            <li>If you knowingly submit Personal Data through a Simplicity Commerce website for the purpose of
                                registering for a service, a contest, or authentication.
                            </li>
                        </ul>
                        <p>During these instances, we may collect data such as, but not restricted to: areas of the
                            Services or
                            Simplicity Commerce websites you visit, transaction type(s) you engage in or request (and amounts
                            thereof),
                            content you view, your IP address, data downloaded or submitted by you, payment information
                            provided
                            by you, shipping and billing information entered by you, as well as the nature, quantity and
                            price of
                            the goods or services you exchange and the individuals or entities with whom you communicate
                            or
                            transact business using the Services.</p>
                        <p>In the event Simplicity Commerce requests Personal Data for scenarios independent of the above,
                            such as
                            marketing-related questions via questionnaires, surveys, and profile data, it will include a
                            specific
                            consent request. The consent request will include a clear purpose and goal for the
                            collection of
                            Personal Data, along with a means of withdrawing consent. In these scenarios, we may ask for
                            data such
                            as, but not limited to: your contact information (name, telephone numbers, email address,
                            mailing
                            address), date of birth, product and/or cosmetic concerns, which brands and products you
                            use, user
                            authentication and security information (e.g. username and password).</p>
                        <p>If at any point you wish to withdraw consent to Personal Data collection, please contact the
                            Simplicity Commerce Privacy Officer via the Contact &amp; Questions area at the bottom of this Privacy
                            Statement.
                            Please note that certain Services may only be able to be offered or provided to you if you
                            disclose
                            the Personal Data necessary to facilitate those Services, and therefore Simplicity Commerce may
                            not be able
                            to provide you with certain Services in the event that you choose not to disclose that
                            Personal Data
                            to Simplicity Commerce.</p>
                        <h2 id="age-of-consent">Age of Consent</h2>
                        <p>The Services offered by Simplicity Commerce are directed towards and designed for the use of
                            persons above
                            the age of majority in your province, state, or country. Persons under the age of majority
                            are not
                            permitted to use the Services on their own, and Simplicity Commerce will not approve applications
                            of, or
                            establish, or maintain accounts or memberships for, any persons below their respective
                            region’s age of
                            majority.</p>
                        <p>Simplicity Commerce does not solicit or knowingly collect Personal Data from persons below the age
                            of
                            majority of their region. If we discover we have received Personal Data of a person below
                            the age of
                            majority, we will delete such information from our systems. Additionally, if a parent or
                            legal
                            guardian believes that Personal Data regarding a minor in their care has been provided to
                            Simplicity Commerce, they may request the minor’s information be corrected or deleted by contacting the
                            Simplicity Commerce Privacy Officer via the Contact &amp; Questions area at the bottom of this Privacy
                            Statement.</p>
                        <h2 id="anonymous-information">Anonymous Information</h2>
                        <p>When you interact with a Simplicity Commerce Service, similar to most other websites, apps, and
                            online
                            services, certain anonymous technical information about your visit is automatically logged
                            and
                            collected by Simplicity Commerce. This may include information about the type of browser you use,
                            operating
                            system, the date and time you access the Service, the links you accessed while using the
                            Service, and
                            the internet address of the website, if any, which linked directly to the Simplicity Commerce
                            Service. This
                            information is used for system administration purposes such as diagnosing problems with Simplicity Commerce’s Services, servers and websites, compiling aggregated and statistical information,
                            and to
                            improve the operation and content of Simplicity Commerce’s websites and Services. It is not
                            personally
                            identifiable, and is not considered Personal Data and subject to this Privacy Statement.</p>
                        <h2 id="personal-data-use">Personal Data Use</h2>
                        <p>Simplicity Commerce may use collected Personal Data for such purposes as:</p>
                        <ul>
                            <li>Helping to establish and verify the identity of users, and to keep user accounts
                                secure,
                            </li>
                            <li>Opening, maintaining, administering and servicing users’ accounts or memberships,</li>
                            <li>Providing Services and support to users,</li>
                            <li>Improving Simplicity Commerce’s websites, including tailoring its websites to users’
                                preferences,
                            </li>
                            <li>Providing users with product or Service updates, promotional notices and offers, and
                                other
                                information about Simplicity Commerce and its affiliates,
                            </li>
                            <li>Corresponding with you, and responding to your questions, inquiries, comments, and
                                instructions,
                            </li>
                            <li>Maintaining the security and integrity of Simplicity Commerce systems, and,</li>
                            <li>Complying with applicable laws.</li>
                        </ul>
                        <p>Once collected, Simplicity Commerce will store and process your Personal Data in secure locations.
                            Simplicity Commerce may transmit data outside of Canada for the purposes of processing and executing
                            transactions
                            related to the Services, or for the purpose of executing transactions on behalf of merchants
                            that have
                            installed and make use of Simplicity Commerce Apps in connection with their online stores. Where
                            this
                            transmission occurs, the security measures outlined in this Privacy Statement will continue
                            to
                            apply.</p>
                        <p>Personal Data will only be retained by Simplicity Commerce for the length of time required to
                            fulfill the
                            purpose or complete the transaction for which it was collected, or as may be required by
                            law. Beyond
                            that point, Personal Data in the possession or control of Simplicity Commerce will be anonymized
                            or securely
                            destroyed.</p>
                        <h2 id="legal-basis-for-processing">Legal Basis for Processing</h2>
                        <p>This section addresses the legal basis for processing your Personal Data if you reside
                            outside of
                            Canada and in the European Economic Area (within Canada, you typically provide consent when
                            you
                            receive notice of this Privacy Statement in a website link or mobile app).</p>
                        <h3 id="lawful-basis-for-processing">Lawful Basis for Processing</h3>
                        <p>Data protection law in the European Union requires a “lawful basis” for collecting and
                            retaining
                            Personal Data from citizens or residents of the European Economic Area. Simplicity Commerce
                            collects and
                            processes your Personal Data for a variety of purposes outlined in this Privacy Statement.
                            In certain
                            cases, separate consent to this processing is not required, including:</p>
                        <ul>
                            <li>For the performance of a contract: To perform our contractual obligations to you,
                                including
                                account registration, fulfilling orders or purchases you have made (including processing
                                of
                                payment), contacting you in relation to any issues with your order, in relation to the
                                provision of
                                the Services, where Simplicity Commerce needs to provide your Personal Data to our service
                                providers to
                                provide the Services, or to aggregate and centralize data for the performance of the
                                Services.
                            </li>
                            <li>To meet legal obligations: To comply with laws, regulations, court orders, or other
                                legal
                                obligations or to assist in an investigation.
                            </li>
                            <li>For legitimate interests: To operate Simplicity Commerce’s business and provide the Services,
                                other than
                                in performing our contractual obligations to you, except where overridden by the
                                interests or
                                fundamental rights and freedoms that require protection of Personal Data. For example,
                                the following
                                areas include processing permitted due to legitimate interests:
                                <ul>
                                    <li>Communication. To communicate with you regarding the Services, including to
                                        provide you
                                        important notices regarding changes to Simplicity Commerce’s Terms of Use, and also to
                                        address your
                                        requests, inquiries, and complaints. Simplicity Commerce may send strictly necessary
                                        communications,
                                        including emails, even if you have opted out of receiving other Simplicity Commerce
                                        emails or
                                        communications. These types of communications do not require consent. Simplicity Commerce also
                                        processes your Personal Data for our legitimate interests when you communicate
                                        with us,
                                        including when you sign up for promotional materials and Simplicity Commerce has not
                                        asked you for
                                        your consent in that regard.
                                    </li>
                                    <li>Respond to Your Requests. To respond to your requests for technical support,
                                        online services,
                                        product information or to any other communication you initiate. This includes
                                        accessing your
                                        account to address technical support requests.
                                    </li>
                                    <li>Promotional Messages. Simplicity Commerce processes your non-sensitive Personal Data
                                        to provide you
                                        with promotional messages, including when you communicate with Simplicity Commerce or
                                        sign up for
                                        promotional materials, when you participate in special activities, offers, or
                                        programs, when we
                                        aggregate and centralize data, and when we share Personal Data with our service
                                        providers and
                                        vendors.
                                    </li>
                                    <li>Surveys. To send you surveys in connection with our Services, unless commercial
                                        in nature. In
                                        those cases, a survey request may be sent to you if you have given Simplicity Commerce
                                        your consent to
                                        receive marketing from us.
                                    </li>
                                    <li>Compliance with Law and Public Safety . To assist in the investigation of
                                        suspected illegal or
                                        wrongful activity, including sharing information with other entities for fraud,
                                        loss, and crime
                                        prevention purposes. To protect and defend Simplicity Commerce’s rights and property,
                                        or the rights or
                                        safety of third parties.
                                    </li>
                                    <li>Improvement and Development. To develop, provide, enhance, and improve Simplicity Commerce Services
                                        and your experience, including to enable you to use the full range of our
                                        Services. For internal
                                        purposes related to certain research, analytics, innovation, testing,
                                        monitoring, customer
                                        communication, risk management, and administrative purposes.
                                    </li>
                                    <li>Enforcing Terms and Notice. To enforce Simplicity Commerce’s Terms of Use or this
                                        Privacy Statement,
                                        or agreements with third parties.
                                    </li>
                                    <li>Merger or Acquisition (Note that certain country/region-specific disclosures may
                                        also apply,
                                        depending upon the jurisdiction in which you reside). To support a contemplated
                                        reorganization
                                        or an actual reorganization of Simplicity Commerce’s business, in connection with
                                        financing, a sale,
                                        or other transaction involving the disposal of all or part of our business or
                                        assets, including
                                        for the purpose of permitting the due diligence required to decide whether to
                                        proceed with a
                                        transaction.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h3 id="consent-as-a-basis-for-processing">Consent as a Basis for Processing</h3>
                        <p>In some cases, Simplicity Commerce will ask for your consent to process your Personal Data. You may
                            indicate your consent in a number of ways, including, as may be presented by Simplicity Commerce
                            and
                            permitted by law, ticking a box (or equivalent action) to indicate your consent when
                            providing us with
                            your Personal Data through our Services or a form, or registering or creating an account
                            with us. Note
                            that certain country/region-specific rules regarding consent may also apply, depending upon
                            the
                            jurisdiction in which you reside.</p>
                        <h2 id="security">Security</h2>
                        <p>Simplicity Commerce maintains reasonable physical, technical, and administrative security measures
                            to
                            minimize the risk of unauthorized loss, theft, copying, misuse, access, disclosure,
                            alteration, or
                            destruction of your Personal Data.</p>
                        <p>If transactions are offered as part of a Simplicity Commerce Service, transaction information is
                            transmitted to and from Simplicity Commerce in encrypted form using industry-standard Secure
                            Socket Layer
                            (SSL) connections to help protect such information, including Personal Data transmitted in
                            the course
                            of these transactions, from interception.</p>
                        <p>Simplicity Commerce also restricts access to your Personal Information to only those persons who
                            have a
                            legitimate business need or legal requirement to view it in connection with the Services.
                            You, as a
                            Personal Data owner, may also authorize any persons you may choose to have access to your
                            Personal
                            Data.</p>
                        <p>Although Simplicity Commerce does utilize security measures appropriate to the level of risk, no
                            method of
                            data transfer or storage on the internet is 100% secure and security risks cannot be
                            eliminated
                            entirely. As such, Simplicity Commerce cannot guarantee perfect security, integrity, or
                            confidentiality of
                            Personal Data.</p>
                        <p>Simplicity Commerce maintains a security incident response protocol to be put in place in the event
                            that
                            the security of your Personal Data in the possession or control of Simplicity Commerce is
                            compromised. In
                            the event of a data breach or security incident involving the Services, Simplicity Commerce will
                            apply this
                            protocol to enable Simplicity Commerce to effectively and efficiently respond to, and contain, the
                            breach or
                            incident. Simplicity Commerce may also seek to notify you in such event. If notification is
                            appropriate or
                            required, Simplicity Commerce may notify you by email, messaging to your device, or other
                            reasonable
                            means.</p>
                        <h2 id="disclosure-of-personal-data">Disclosure of Personal Data</h2>
                        <p>Simplicity Commerce does not provide Personal Data to unaffiliated third parties for their use in
                            marketing
                            directly to you. We may use unaffiliated companies, or trusted third party service
                            providers, to help
                            maintain and operate our Services for reasons related to our business operations and to
                            better serve
                            you, and those companies may receive your Personal Data for that purpose. For example, Simplicity Commerce
                            may use
                            third party payment processor services in connection with the Services and its websites, and
                            the
                            payment information that you provide to Simplicity Commerce may be disclosed to and used by these
                            payment
                            processors for the purposes of completing and executing transactions requested or initiated
                            by you.
                            When Simplicity Commerce shares Personal Data with third-party services that support our delivery
                            of the
                            Services, we require that they use your Personal Data only for the purposes we’ve
                            authorized, and that
                            they protect your Personal Data to at least the same standards used by Simplicity Commerce.</p>
                        <p>As part of agreements with merchants who have installed Simplicity Commerce Apps and the execution
                            of
                            transactions on behalf of merchants that make use of Simplicity Commerce Apps in connection with
                            their
                            online stores, we may disclose Personal Data which has been collected by us that is specific
                            to that
                            merchant’s store and your transactions in connection therewith.</p>
                        <p>Simplicity Commerce may also disclose Personal Data about you in connection with legal
                            requirements, such
                            as in response to an authorized subpoena, governmental request or investigation, or as
                            otherwise
                            permitted by applicable law (including, without limitation, to prevent fraud or abuse, or to
                            protect
                            Simplicity Commerce’s legal rights, property, or the safety of Simplicity Commerce, its employees, users
                            or
                            others).</p>
                        <p>Finally, as Simplicity Commerce’s business develops, it may sell or buy corporate assets, and in
                            such
                            transactions Personal Data may be one of the transferred business assets. If Simplicity Commerce,
                            its
                            internet businesses, or substantially all of its shares or assets, is acquired or an
                            acquisition is
                            contemplated, Personal Data may be one of the assets assessed or transferred in connection
                            with that
                            transaction.</p>
                        <p><a href="/data-processors">A list of Data Processors engaged by Simplicity Commerce who may receive
                            Personal Data is available here</a>. New processors will be added to this list 30 days prior
                            to being
                            integrated into our Services. If you are subject to the GDPR and have objections to a Data
                            Processor
                            addition please contact the Simplicity Commerce Privacy Officer via the Contact &amp; Questions
                            area at the
                            bottom of this Privacy Statement. Objections to Data Processors may impact the Services
                            which can be
                            offered or provided to you by Simplicity Commerce.</p>
                        <p>If you believe your Personal Data has been disclosed other than as described in this Privacy
                            Statement, please contact the Simplicity Commerce Privacy Officer via the Contact &amp; Questions
                            area at
                            the bottom of this Privacy Statement.</p>
                        <h2 id="transfers-of-your-personal-data-to-other-countries">Transfers of Your Personal Data to
                            Other
                            Countries</h2>
                        <p>The Personal Data Simplicity Commerce processes, and associated Services and systems, may be housed
                            on
                            servers in various locations where Simplicity Commerce maintains servers or facilities, including
                            Canada,
                            the United States, and the EU. Please be aware that Personal Data we collect may be
                            processed and
                            stored in one or more of these locations. The data protection and privacy laws in these
                            locations may
                            offer a different level of protection than in your country/region, however, as noted earlier
                            in this
                            Privacy Statement, Simplicity Commerce takes steps, including through contracts, intended to
                            ensure that the
                            Personal Data it collects continues to be protected wherever it is located in a manner
                            consistent with
                            the standards of protection required under applicable law.</p>
                        <p>Where Personal Data is transferred from the European Economic Area to a country that has not
                            received
                            an adequacy decision by the European Commission, Simplicity Commerce relies on appropriate
                            safeguards, such
                            as for example the European Commission-approved Standard Contractual Clauses and EU-U.S.
                            Privacy
                            Shield Frameworks, to transfer the Personal Data.
                            By using our Services and submitting your Personal Data, you agree to the transfer, storage,
                            and/or
                            processing of your Personal Data in the locations contemplated above. Where and as required,
                            we will
                            seek your explicit consent as outlined in this Privacy Statement.</p>
                        <h2 id="cookies-and-advertisers">“Cookies” and Advertisers</h2>
                        <p>The Simplicity Commerce websites, or the third party companies used to host, operate, or maintain
                            these
                            websites, may place a “cookie” on your computer in order to allow you to use these websites
                            and to
                            personalize your experience.</p>
                        <p>A “cookie” is a small piece of data, or an alphanumeric identifier, that can be sent by a web
                            server
                            to your computer or device, which then may be stored by your browser on your computer or
                            device.
                            Cookies allow Simplicity Commerce to recognize your computer or device while you are on our
                            websites and
                            help customize your online experience and make it more convenient for you. Cookies are also
                            useful in
                            allowing more efficient log-in for users, tracking transaction histories, and preserving
                            information
                            between browsing sessions. The information collected from cookies may also be used to
                            improve website
                            functionality.</p>
                        <p>The advertisers and/or other content providers that may appear on Simplicity Commerce websites may
                            also use
                            cookies that are not sent by Simplicity Commerce. Such ads or content may contain cookies that
                            help track
                            and target the interests of users of our websites in order to present “personalized”
                            advertisements or
                            other messages that the user might find interesting. Simplicity Commerce is not responsible for
                            any such
                            cookies.</p>
                        <p>Most web browsers have features that can notify you when you receive a cookie or prevent
                            cookies from
                            being sent. If you disable cookies, however, you may not be able to use certain personalized
                            functions
                            of Simplicity Commerce websites.</p>
                        <h2 id="rights-with-respect-to-personal-data">Rights With Respect to Personal Data</h2>
                        <p>Simplicity Commerce is committed to ensuring you retain full access to and control of your Personal
                            Data.
                            To that end, we endeavor to respect your right to be informed regarding the collection, use
                            and
                            disclosure of Personal Data, and your right of correction and access to it, via this Privacy
                            Statement.</p>
                        <p>If you would like to access, correct, remove, request a copy of, withdraw consent to
                            collection of
                            your Personal Data, or are looking for any additional information on how your Personal Data
                            may be
                            collected, used or disclosed by Simplicity Commerce, please contact the Simplicity Commerce Privacy
                            Officer via
                            the Contact &amp; Questions area at the bottom of this Privacy Statement. Subject to certain
                            exceptions and limitations that may be prescribed by applicable law, you will be provided
                            with
                            reasonable access to your Personal Data, and will be entitled to have it amended or
                            corrected as
                            appropriate.</p>
                        <p>In certain circumstances, you may have the right to have your Personal Data, or certain
                            components of
                            your Personal Data, erased by Simplicity Commerce, to have your Personal Data moved, copy or
                            transmitted
                            from Simplicity Commerce’s systems to other systems, or to object to or restrict certain
                            processing of your
                            Personal Data by Simplicity Commerce. In the event that you wish to inquire about, or seek to
                            exercise any
                            of these rights (as they may be applicable), please contact Simplicity Commerce’s Privacy
                            Officer.</p>
                        <h2 id="external-links">External Links</h2>
                        <p>Simplicity Commerce Services may contain links to other websites, apps, or services, including
                            those of
                            advertisers or third party content providers who offer downloads as part of a Simplicity Commerce
                            Service.
                            Simplicity Commerce is not responsible for the privacy practices or the content of other websites,
                            apps, or
                            services. We encourage you to read the privacy statements published by such third parties
                            before
                            divulging your Personal Data to them.</p>
                        <h2 id="changes-to-this-privacy-statement">Changes to this Privacy Statement</h2>
                        <p>Simplicity Commerce reserves the right to modify or supplement this Privacy Statement in its
                            discretion, at
                            any time. If a material change to the terms of this Privacy Statement is made, we will post
                            a notice
                            to our Blog and a link to the new or amended Privacy Statement. The collection, use and
                            disclosure of
                            your Personal Data by Simplicity Commerce will be governed by the version of this Privacy
                            Statement in
                            effect at that time. Your continued use of Simplicity Commerce’s websites and/or Services
                            subsequent to any
                            changes to this Privacy Statement will indicate your consent to the collection, use and
                            disclosure of
                            your Personal Data in accordance with the amended Privacy Statement.</p>
                        <h2 id="contact--questions">Contact &amp; Questions</h2>
                        <p>If you have any questions or comments regarding this Privacy Statement or any aspects of Simplicity Commerce Services, please contact the Simplicity Commerce Privacy Officer at <a
                                href="mailto:privacy@simplicitycommerce.com" target="_blank">privacy@simplicitycommerce.com</a>.</p>
                        <p>Subject to applicable law, if you are a citizen or resident of the European Economic Area,
                            you also
                            have the right to (i) object to Simplicity Commerce’s use of your Personal Data and (ii) lodge a
                            complaint
                            with your local data protection authority.</p>
                      {/*<p>VeraSafe has been appointed as the European Union representative for Simplicity Commerce related to
                            data
                            protection matters, pursuant to Article 27 of the General Data Protection Regulation of the
                            European
                            Union. On matters related to the processing of personal data, VeraSafe can be contacted in
                            addition to
                            the Simplicity Commerce Privacy Officer at <a href="mailto:privacy@simplicitycommerce.com"
                                                                    target="_blank">privacy@boldcommerce.com</a>. To
                            make such an
                            inquiry, please contact VeraSafe using this contact form: <a
                                href="https://www.verasafe.com/privacy-services/contact-article-27-representative"
                                target="_blank">https://www.verasafe.com/privacy-services/contact-article-27-representative</a>
                        </p>
                        <p>Alternatively, VeraSafe can be contacted at:</p>
                        <p>VeraSafe Czech Republic s.r.o<br/>
                            Klimentská 46<br/>
                            Prague 1, 11002<br/>
                            Czech Republic
                        </p>
                        <p>VeraSafe Ireland Ltd<br/>
                            Unit 3D North Point House<br/>
                            North Point Business Park<br/>
                            New Mallow Road<br/>
                            Cork T23AT2P<br/>
                            Ireland
                        </p>
                        <p>VeraSafe Netherlands BV<br/>
                            Keizersgracht 391 A<br/>
                            1016 EJ Amsterdam<br/>
                            The Netherlands
                        </p>*/}
                        <br/>
                    </TextWrapper>
                </MainContentSection>
            </Container>
        </MainWrapper>
);
}

export default ErrorPage;
